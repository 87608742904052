<template>
  <div ref="document">
    <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page" :aria-label="`${constData.chouhyouId}-${chouhyou.page}`">
      <div>
        <TemplateNormal :id="constData.chouhyouId + chouhyou.page" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateNormal from '@/assets/svg/common/return_request/normal.svg';
import { base64Decode, executeTransactSqls, setPaperA4, splitMultiRowString } from '@/assets/js/common';
import { ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { listV_HACCHU_DATA_WITH_MEISAI, listV_CENTER_HACCHU_DATA_WITH_MEISAI, getM_SHOKISETTEI, getT_CHOHYO_COUNTER } from '@/graphql/queries';
import { executeTransactSql } from "@/graphql/mutations";
import { API, graphqlOperation } from 'aws-amplify';
import html2pdf from 'html2pdf.js'
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: 'ReturnRequest',
  /* コンポーネント */
  components: {
    TemplateNormal,
  },
  /* パラメータ */
  props:['params'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 20,
        cntStart: 20,
        cntMiddle: 20,
        cntEnd: 20,
        tempKbnNormal: 1,
        tempKbnStart: 2,
        tempKbnMiddle: 3,
        tempKbnEnd: 4,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '返品依頼書',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '210mm',
        height: '295.01mm',
      },
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],

      // 返品伝票番号
      returnSlipNumber: 0,

      // デコード・パースした引数
      parsedParams: null,
      //総ページ数
      pageCount: 0,
    }
  },
  created() {
    // ヘッダ・フッタを非表示
    this.$store.commit("setShowNav", false);
  },
  /* マウント */
  mounted() {
    // ローディングON
    this.$store.commit("setLoading", true);

    // 印刷レイアウト設定
    setPaperA4();

    this.fetchData();
  },
  /* 関数群 */
  methods:{
    async fetchData(){
      // 指定された発注データを設定
      await this.setHacchuData();
    },
    /* 発注データ設定 */
    async setHacchuData(){
      // 引数をデコード
      const decodedParams = await base64Decode(this.params);
      console.log({decodedParams});
      // JSONパース
      this.parsedParams = JSON.parse(decodedParams);
      const isCenter = this.parsedParams.isCenter;
      console.log({isCenter});

      // WHERE句生成
      let whereOptions = `AND HACCHU_DATA_ID = ${this.parsedParams.hacchu_data_id} AND DENPYO_KBN = 2`;
      // 発注データID
      console.log(whereOptions);

      try {
        let result;
        let orderData;
        if(isCenter) {
          result = await API.graphql(graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI,{where_options: whereOptions}));
          orderData = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
        } else {
          result = await API.graphql(graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI,{where_options: whereOptions}));
          orderData = result.data.listV_HACCHU_DATA_WITH_MEISAI;
        }
        console.log({orderData});
        if(orderData && orderData.length > 0){
          // 返品伝票番号を採番
          await this.getReturnSlipNumber();
          // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
          await this.initListChouhyou(orderData.length);
          // 共通の置換文字列設定
          await this.createReplacementsCommon();
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(orderData);
          // 作成した置換文字データをSVGファイルに設定
          // await this.setChouhyou();
          const chouhyouSet = await this.setChouhyou();

          if(chouhyouSet) {
            const result = await this.exportToPDF();
            if(result){
              // console.log({result});
              await this.updateFlg(orderData);
            }
          }
        }
        // ローディングOFF
        this.$store.commit("setLoading", false);
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'setHacchuData', TYPE["0004"], error);
        // ローディングOFF
        this.$store.commit("setLoading", false);
        // アラート出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
      }
    },
    /* */
    async getReturnSlipNumber() {
      // メソッド名を定義
      const method_name="getReturnSlipNumber"
      // ログインユーザーデータ
      const user = this.$store.getters.user;
      console.log({user});

      // 発注データテーブルへの登録
      // コード最大値の取得
      let sqlCounterTables;
      // 検索条件
      let condition = {
        BUMON_CD: user.BUMON_CD,
      };
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let getResult = await API.graphql(
          graphqlOperation(getT_CHOHYO_COUNTER, condition)
        );
        console.log(
          "getT_CHOHYO_COUNTER：result = " +
            { getResult } +
            ", condition = " +
            JSON.stringify(condition)
        );

        // 結果判定
        if (getResult.data.getT_CHOHYO_COUNTER) {
          // データを取得できた場合
          // DBから取得した値を設定
          let resultCounterData = getResult.data.getT_CHOHYO_COUNTER;
          console.log({resultCounterData});
          // カウンタが最大値（99999）到達時は、1から採番
          this.returnSlipNumber =
            resultCounterData.HENPIN_DENPYO_NO === 99999
              ? 1
              : resultCounterData.HENPIN_DENPYO_NO + 1;
          sqlCounterTables = `HENPIN_DENPYO_NO = ${this.returnSlipNumber},`;
          console.log(
            "getT_CHOHYO_COUNTER：registerOrderNo = " +
              this.returnSlipNumber +
              ", sqlCounterTables = " +
              sqlCounterTables
          );

          // カウンタテーブルの値を更新
          let sqlList = [];
          sqlList.push(
            `UPDATE T_CHOHYO_COUNTER SET ` +
              sqlCounterTables +
              ` UPDATE_DATE = CURDATE(),` +
              ` UPDATE_USER_NAME = '${user.LOGIN_USER_CD}'`+
              ` WHERE BUMON_CD = '${user.BUMON_CD}';`
          );

          condition = { SQLs: sqlList };
          console.log({condition});

          try {
            // AppSync→AuroraServerless(MySQL)でデータ取得
            let transactionResult = await API.graphql(
              graphqlOperation(executeTransactSql, condition)
            );

            // レスポンスデータを取得できた際の処理
            if (transactionResult) {
              const responseBody = JSON.parse(transactionResult.data.executeTransactSql.body);
              console.log({responseBody});

              // SQL実行でエラーが発生した場合の処理
              if (transactionResult.data.executeTransactSql.statusCode > 200) {
                // レスポンスメッセージ
                // let message = responseBody.message;
                // // エラー内容
                const errorMessage = responseBody.error;
                console.error({ errorMessage });
                console.info("insertTable：エラー" + responseBody);
                // ログ出力(Lamda関数でのSQL実行でステータスエラー)
                addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
                console.info("insertTable：エラー" + responseBody);
                return false;
              } else {
                // SQLが正常に終了した際の処理
                // 何もしない
              }
            } else {
              // レスポンスデータを取得できなかった際の処理
              console.log("insertTable：エラー2 : " + JSON.stringify(transactionResult));
              // ログ出力(Lamda関数でのSQL実行でステータスエラー)
              addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            }
          } catch (error) {
            console.log({error});
            console.log("insertTable : 異常終了 = " + { error });
            // ログ出力(exception)
            addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
            return false;
          }
        } else {
          if (getResult.errors) {
            // データを取得できなかった場合
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getT_CHOHYO_COUNTER(error2)： error = " + { error }
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(productCnt){
      // console.log('initListChouhyou');
      let productCntNokori = productCnt;
      let page = 1;
      while (productCntNokori > this.constData.cntNormal) {
        // 残りの製品件数が最終帳票の件数に収まらない場合、帳票を出力し続ける
        this.listChouhyou.push({page: page, replacements: []});
        productCntNokori -= this.constData.cntNormal;
        page++;
      }
      // 終了帳票
      this.listChouhyou.push({page: page, replacements: []});
      // 総ページ数を保持
      this.pageCount = page;
    },
    /* 共通の置換配列セット */
    async createReplacementsCommon(){
      // console.log('createReplacementsCommon');
      const now = new Date();
      const today = `${now.getFullYear()}年${now.getMonth()+1}月${now.getDate()}日`;
      // 作成日付
      this.replacementsCommon.push({key: '%依頼日%', value: today, textAnchor: 'end', textLength: 231});
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result){
      console.log('createReplacementsPage');
      let productsIndex = 0;
      let whiteFlg = false;
      console.log(`listChoiuhyou.length = ${ this.listChouhyou.length}`);
      for (let i = 0; i < this.listChouhyou.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];
        // 営業所情報取得
        const officeInfo = await this.getOfficeData();
        // 営業所情報設定
        await this.setOfficesData(replacements, officeInfo);
        // 返品依頼No
        replacements.push({key: '%伝番%', value: this.returnSlipNumber ?? '', textAnchor: 'start', textLength: 233});
        // 担当者名
        replacements.push({key: '%担当者名%', value: result[0].TANTOSHA_NAME ?? '', textAnchor: 'start', textLength: 176});
        // 件名
        replacements.push({key: '%件名%', value: result[0].KENMEI_NAME ?? '', textAnchor: 'start', textLength: 844});
        // 仕入先名称
        replacements.push({key: '%仕入先名%', value: result[0].SHIIRESAKI_NAME ?? '', textAnchor: 'start', textLength: 569});
        // 注番
        replacements.push({key: '%注番%', value: result[0].CHUMON_NO ?? '', textAnchor: 'end', textLength: 126});
        // 摘要
        this.setTekiyo(replacements, result[0].TEKIYO);

        // 帳票毎に設定可能な製品の件数
        let productCntByChouhyou = this.constData.cntNormal;

        // 製品
        for (let j = 0; j < productCntByChouhyou; j++){
          if(productsIndex < result.length){
            // 型番
            replacements.push({key: `%型番${j.toString()}%`, value: result[productsIndex].SHOHIN_NAME ?? '', textAnchor: 'start', textLength: 414});
            // 数量
            replacements.push({key: `%数${j.toString()}%`, value: result[productsIndex].SURYO_SU ? (result[productsIndex].SURYO_SU * -1).toLocaleString() : '', textAnchor: 'end', textLength: 104});
            // 良品
            replacements.push({key: `%G${j.toString()}%`, value: result[j].HENPIN_STATUS_KBN == 1 ? '●' : '', textAnchor: 'middle', textLength: 79});
            // 不良品
            replacements.push({key: `%B${j.toString()}%`, value: result[j].HENPIN_STATUS_KBN == 2 ? '●' : '', textAnchor: 'middle', textLength: 79});
            // 単価
            replacements.push({key: `%単価${j.toString()}%`, value: result[productsIndex].NET_GK?.toLocaleString() ?? '', textAnchor: 'end', textLength: 119});
            // 日付
            replacements.push({key: `%日付${j.toString()}%`, value: result[productsIndex].CHUMON_DATE ?? '', textAnchor: 'end', textLength: 184});
            // 注番
            replacements.push({key: `%注番${j.toString()}%`, value: result[productsIndex].CHUMON_NO ? `${officeInfo.HACCHU_NO}-${result[productsIndex].CHUMON_NO}` : '', textAnchor: 'end', textLength: 191});
            // 備考
            this.setSummary(replacements, j, result[productsIndex].BIKO);

            productsIndex++;
          } else {
            // 数量
            replacements.push({key: `%数${j.toString()}%`, value: '', textAnchor: 'end', textLength: 104});
            // 良品
            replacements.push({key: `%G${j.toString()}%`, value: '', textAnchor: 'middle', textLength: 79});
            // 不良品
            replacements.push({key: `%B${j.toString()}%`, value: '', textAnchor: 'middle', textLength: 79});
            // 単価
            replacements.push({key: `%単価${j.toString()}%`, value: '', textAnchor: 'end', textLength: 119});
            // 日付
            replacements.push({key: `%日付${j.toString()}%`, value: '', textAnchor: 'end', textLength: 184});
            // 注番
            replacements.push({key: `%注番${j.toString()}%`, value: '', textAnchor: 'end', textLength: 191});
            // 備考
            for (let k = 0; k < 3; k++) {
              replacements.push({key: `%備考${j.toString()}-${k.toString()}%`, value: '', textAnchor: 'start', textLength: 263});
            }

            // 型番
            if (whiteFlg == true) {
              // 上記以外で以下余白を設定済み
              replacements.push({key: `%型番${j.toString()}%`, value: '', textAnchor: 'start', textLength: 414});
              // console.log(`空白 = ${j}`);
            } else {
              // 以下余白が入る箇所
              if ( i + 1 == this.listChouhyou.length && j + 1 == productCntByChouhyou ) {
                replacements.push({key: `%型番${j.toString()}%`, value: '以上', textAnchor: 'middle', textLength: 414});
                // console.log(`以上：j = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
              } else {
                replacements.push({key: `%型番${j.toString()}%`, value: '以下余白', textAnchor: 'middle', textLength: 414});
                // console.log(`以下余白 = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
              }
              whiteFlg = true;
            }
          }
        }
        // ページ番号
        replacements.push({key: '%ページ番号%', value: `${this.listChouhyou[i].page} / ${this.pageCount} ページ`, textAnchor: 'start', textLength: 196});

        this.listChouhyou[i].replacements = replacements;
      }
    },
    /* 営業所情報取得 */
    async getOfficeData() {
      // 部門コード
      const user = this.$store.getters.user;
      const bumonCd = user.BUMON_CD;

      // 営業所データ取得
      let result = await API.graphql(graphqlOperation(getM_SHOKISETTEI, {BUMON_CD: bumonCd}));
      // console.log({result});
      const officeInfo = result.data.getM_SHOKISETTEI;
      return officeInfo;
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements, officeInfo) {
      // // TODO データが存在しなかった場合の処理
      // const bumon = bumonData[0];
      // console.log({bumon});

      // 部門名称
      replacements.push({key: '%部門名%', value: officeInfo.BUMON_NAME ?? '', textAnchor: 'start', textLength: 171});
      // 郵便
      replacements.push({key: '%郵便番号%', value: officeInfo.YUBIN_NO ?? '', textAnchor: 'start', textLength: 175});
      // 住所
      replacements.push({key: '%住所%', value: officeInfo.JUSHO_NAME ?? '', textAnchor: 'start', textLength: 492});
      // TEL
      replacements.push({key: '%TEL%', value: officeInfo.DENWA_NO ?? '', textAnchor: 'start', textLength: 199});
      // FAX
      replacements.push({key: '%FAX%', value: officeInfo.FAX_NO ?? '', textAnchor: 'start', textLength: 199});
    },
    /* 置換文字列に摘要を設定 */
    async setTekiyo(replacements, summary) {
      let arySummary = splitMultiRowString(summary, 52);
      for (let i = 0; i < 5; i++) {
        if (i < arySummary.length) {
          replacements.push({key: `%摘要${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 1566});
        } else {
          replacements.push({key: `%摘要${i.toString()}%`, value: '', textAnchor: 'start', textLength: 1566});
        }
      }
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements, rowNum, summary) {
      let arySummary = splitMultiRowString(summary, 17);
      for (let i = 0; i < 3; i++) {
        if (i < arySummary.length) {
          // 備考
          replacements.push({key: `%備考${rowNum}-${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 277});
        } else {
          // 備考
          replacements.push({key: `%備考${rowNum}-${i.toString()}%`, value: '', textAnchor: 'start', textLength: 277});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        console.log(`this.constData.chouhyouId + this.listChouhyou[i].page = ${this.constData.chouhyouId + this.listChouhyou[i].page}`);
        let svgDoc = document.getElementById(this.constData.chouhyouId + this.listChouhyou[i].page);
        this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
      return true;
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements(node, replacements){
      // console.log('setReplacements');
      for (const textElement of node.getElementsByTagName('text')) {
        for (const child of textElement.children) {
          if(child.tagName == 'tspan'){
            for (const commonReplacement of this.replacementsCommon) {
              if(child.innerHTML.indexOf(commonReplacement.key) != -1){
                this.setTspan(textElement, child, commonReplacement);
                break;
              }
            }
            for(let i = 0; i < replacements.length; i++){
              if(child.innerHTML.indexOf(replacements[i].key) != -1){
                this.setTspan(textElement, child, replacements[i]);
                replacements.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      for (const gElement of node.getElementsByTagName('g')) {
        this.setReplacements(gElement, replacements);
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    /* 帳票をPDFダウンロード */
    exportToPDF() {
      // console.log('export to PDF');
      // console.log(this.$refs.document);
      try {
        const pages = Array.from(document.querySelectorAll(`div[aria-label^="${this.constData.chouhyouId}-"]`));

        let worker = html2pdf()
          .set({
            filename: '返品依頼書.pdf',
            image: {type: 'jpeg', quality: 0.95},
            html2canvas: { scale: 2 },
          })
          .from(pages[0])

        if (pages.length > 1) {
          // jsPDFインスタンスを生成
          worker = worker.toPdf()

          // 1ページずつPDFをレンダリング
          for (const page of pages.slice(1)) {
            worker = worker
              .get('pdf')
              .then(pdf => {
                pdf.addPage()
              })
              .from(page)
              .toContainer()
              .toCanvas()
              .toPdf()
          }
        }

        worker = worker.save()
        return true;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'exportToPDF', TYPE["0003"], error);
        return false;
      }
    },
    /* 依頼フラグ・返伝NO更新 */
    async updateFlg(orders) {
      console.log('updateFlg');

      console.log({orders});
      const slipNum = this.returnSlipNumber;
      console.log({slipNum});
      // 依頼フラグ、返伝NOを更新する
      let updateSQL = '';
      if(this.parsedParams.isCenter) {
        updateSQL += 'UPDATE T_CENTER_HACCHU_MEISAI_DATA SET ';
      } else {
        updateSQL += 'UPDATE T_HACCHU_MEISAI_DATA SET ';
      }
      updateSQL += ' IRAI_FLG = 1,';
      updateSQL += ` HENDEN_NO = ${this.returnSlipNumber}`;
      updateSQL += ` WHERE HACCHU_DATA_ID = ${orders[0].HACCHU_DATA_ID}`;
      updateSQL += ` AND DENPYO_KBN = 2`;
      console.log({updateSQL});
      if (!(await executeTransactSqls(this.$route.name, 'updateFlg', updateSQL))) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        return;
      }
    },
  },
}
</script>
<style>
  body
  {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>